.home-page {
  width: 100%;
  .one-block {
    width: 100%;
    background-color: $bg-color;
    padding-top: 80px;
    box-sizing: border-box;
    .one-item {
      width: 240px;
      height: 260px;
      background-color: white;
      padding-top: 30px;
      box-sizing: border-box;
      .icon {
        width: 60px;
        height: 60px;
      }
      .value-top {
        margin-top: 26px;
        box-sizing: border-box;
        margin-left: 10px;
        margin-right: 10px;
        width: 220px;
      }
    }
  }
  .two-block {
    width: 100%;
    background-color: white;
    padding-top: 80px;
    box-sizing: border-box;
    .pic {
      width: 424px;
      height: 228px;
    }
    .top-word {
      width: 428px;
    }
  }
  .app-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 88px;
    .app-item {
      width: 247px;
      height: 352px;
      position: relative;
      margin-right: 70px;
      margin-bottom: 16px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .pic-main {
        width: 247px;
        height: 193px;
      }
      .title {
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        top: 153px;
        height: 40px;
        line-height: 40px;
        background-color: rgba($color: white, $alpha: 0.8);
      }
      .desc {
        width: 226px;
        margin-left: 10px;
        margin-top: 10px;
      }
    }
  }
}
