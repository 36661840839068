.nav-bar {
  width: 100%;
  height: 70px;
  .header {
    box-sizing: border-box;
    .nav-logo {
      width: 143px;
      height: 40px;
      margin-right: 115px;
      box-sizing: border-box;
    }
    .nav-item {
      margin-right: 55px;
      box-sizing: border-box;
      height: 70px;
      line-height: 70px;
      position: relative;

      .item-label {
        color: $text-color;
      }
      .line {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 29px;
        height: 2px;
        background-color: transparent;
      }
      &.active {
        .item-label {
          color: $primary-color;
        }
        .line {
          background-color: $primary-color;
        }
      }
    }
  }
}

.nav-bottom {
  height: 312px;
  background-color: #01244f;
  color: white;
  padding-top: 45px;
  box-sizing: border-box;
  flex-shrink: 0;
  .icon-phone {
    width: 16px;
    height: 16px;
  }
  .line {
    width: 1px;
    height: 182px;
    background-color: white;
    margin-left: 99px;
    margin-right: 99px;
    box-sizing: border-box;
  }
  .qr-block {
    .pic-qr {
      width: 96px;
      height: 96px;
    }
  }
}

.copy-right {
  height: 62px;
  background-color: #0a2342;
  color: white;
}
.slider {
  overflow: hidden;
  .slick-dots {
    bottom: 20px;
    li {
      width: 24px;
      height: 4px;
      button {
        width: 24px;
        height: 4px;
        background: white;
        padding: 0;
        &::before {
          content: '';
        }
      }
      &.slick-active {
        width: 24px;
        height: 4px;
        button {
          background: $primary-color;
        }
      }
    }
  }
}
.zxppc{
  position: fixed;
  right: 10px;
  top: 30%;
  width: 100px;
  height: 200px;
  z-index: 99;
}
.icon_s{
  display: block;
  width: 28px;
  height: 24px;
  background: url('http://yidianchen.com/pic/icon_customer.png') ;
  margin: 0  auto 6px;
}
.ss{
}
.ss li a {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 18px;
}
