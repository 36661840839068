$primary-color: #04bfb5;
$bg-color: #f7f9ff;
$text-color: #000000;
$text-color-3: #333;
$text-color-7: #777777;

$main-width: 1200px;

.main-width {
  width: $main-width;
}
.max-main-width {
  max-width: $main-width;
}
.min-min-width {
  min-width: $main-width;
}

.box-shadow {
  box-shadow: 0 4px 6px 0 rgba($color: #000000, $alpha: 0.1);
}

.text-color {
  color: $text-color;
}
.text-color-3 {
  color: $text-color-3;
}
.text-color-7 {
  color: $text-color-7;
}

$fontSize: 12, 14, 16, 18, 30, 32;

@each $v in $fontSize {
  .font-#{$v} {
    font-size: #{$v}px;
  }
}

.font-w-6 {
  font-weight: 600;
}

.d-flex-r {
  display: flex;
  flex-direction: row;
}
.d-flex-c {
  display: flex;
  flex-direction: column;
}
.handle {
  cursor: pointer;
}

.text-justify {
  text-align: justify;
  // text-align-last: justify;
  text-justify: distribute-all-lines;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}

.ai-center {
  align-items: center;
}
.ai-start {
  align-items: start;
}
.ai-end {
  align-items: end;
}

.jc-start {
  justify-content: flex-start;
}
.jc-center {
  justify-content: center;
}
.jc-between {
  justify-content: space-between;
}
.jc-around {
  justify-content: space-around;
}
.jc-every {
  justify-content: space-evenly;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

$sp: 4, 8, 10, 15, 16, 24, 26, 27, 30, 34, 40, 60, 68, 80, 84, 92, 163;

@each $v in $sp {
  .m-#{$v} {
    margin: #{$v}px;
    box-sizing: border-box;
  }
  .ml-#{$v} {
    margin-left: #{$v}px;
    box-sizing: border-box;
  }
  .mr-#{$v} {
    margin-right: #{$v}px;
    box-sizing: border-box;
  }
  .mt-#{$v} {
    margin-top: #{$v}px;
    box-sizing: border-box;
  }
  .mb-#{$v} {
    margin-bottom: #{$v}px;
    box-sizing: border-box;
  }
  .p-#{$v} {
    padding: #{$v}px;
    box-sizing: border-box;
  }
  .pl-#{$v} {
    padding-left: #{$v}px;
    box-sizing: border-box;
  }
  .pr-#{$v} {
    padding-right: #{$v}px;
    box-sizing: border-box;
  }
  .pt-#{$v} {
    padding-top: #{$v}px;
    box-sizing: border-box;
  }
  .pb-#{$v} {
    padding-bottom: #{$v}px;
    box-sizing: border-box;
  }
}

.bw-1 {
  width: 100%;
}
.bh-1 {
  height: 100%;
}

body {
  font-size: 14px;
  color: $text-color;
  font-family: 'Microsoft YaHei';
}

.btn {
  height: 36px;
  line-height: 36px;
  border-radius: 36px;
  padding: 0 40px;
  box-sizing: border-box;
  color: $primary-color;
  border: 1px solid $primary-color;
  background-color: white;
  &.active {
    background-color: $primary-color;
    color: white;
  }
}
