.case-detail-page {
  .nav-link {
    border-bottom: 1px solid #eeeeee;
  }
  .title {
    width: max-content;
  }
  .more {
    margin-bottom: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid $primary-color;
  }
  .app-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 88px;
    .app-item {
      width: 247px;
      height: 352px;
      position: relative;
      margin-right: 70px;
      margin-bottom: 16px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .pic-main {
        width: 247px;
        height: 193px;
      }
      .app-title {
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        top: 153px;
        height: 40px;
        line-height: 40px;
        background-color: rgba($color: white, $alpha: 0.8);
      }
      .desc {
        width: 226px;
        margin-left: 10px;
        margin-top: 10px;
      }
    }
  }
}
